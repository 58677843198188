<template>
  <div>
    <div class="PolicyLibrary" v-show="isSearch">
      <div class="title_img" @click="advertiseClick">
        <van-image
          width="100%"
          fit="cover"
          :src="
            advertiseImg.img
              ? advertiseImg.img
              : require('@/assets/img/ProductServe_01.jpg')
          "
        />
      </div>
      <div class="search_box">
        <div class="search">
          <van-search
            v-model="value"
            placeholder="搜索您想了解的关键字"
            show-action
            shape="round"
            @focus="searchFocus"
          >
            <template #action>
              <van-button round type="info" @click="onSearch" size="normal"
                >搜索</van-button
              >
            </template>
          </van-search>
        </div>
      </div>

      <div class="PolicyLibrary_bottom">
        <van-tabs v-model="active" @click="goQuiz">
          <van-tab
            :title="item"
            v-for="item in ['政策解读', '政策汇总']"
            :key="item"
          >
            <div class="article_list">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
              >
                <div
                  class="article_list_box"
                  v-for="item in list"
                  :key="item.id"
                  @click="detailsClick(item)"
                >
                  <div class="article_list_top">
                    <div class="article_list_top_lf">
                      <h4 class="van-multi-ellipsis--l2">
                        {{ item.essayTitle }}
                      </h4>
                      <div class="num_time">
                        <div>
                          <van-icon name="eye" />
                          <span>{{ item.clickNum }}</span>
                        </div>
                        <div>
                          {{
                            item.updateTime ? item.updateTime.split("T")[0] : ""
                          }}
                        </div>
                      </div>
                      <!-- <div class="label">
                      <van-tag type="danger" v-if="item == 1">置顶</van-tag>
                      <van-tag type="warning" v-else>推荐</van-tag>
                    </div> -->
                    </div>
                    <!-- <van-image
                      fit="cover"
                      :src="require('@/assets/img/policyLibrary_01.png')"
                      lazy-load
                      class="article_list_top_img"
                    /> -->
                  </div>
                </div>
              </van-list>
            </div></van-tab
          >
        </van-tabs>
      </div>
    </div>
    <search :isSearch.sync="isSearch" />
    <copyrightIp />
  </div>
</template>
<script>
import { findAllTEssay, findAdByLocation } from "@/api/policyLibrary";
import search from "@/components/search.vue";
import copyrightIp from "@/components/copyrightIp";
import {
  Search,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Icon,
  Button,
  Tag,
  Tab,
  Tabs,
  Popup,
  Field,
  List,
} from "vant";
export default {
  components: {
    [Search.name]: Search,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Tag.name]: Tag,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [List.name]: List,
    search,
    copyrightIp,
  },
  data() {
    return {
      advertiseImg: {
        img: "",
        active: "",
      },
      active: "",
      value: "",
      isSearch: true,
      loading: false,
      finished: false,
      listQuery: {
        page: "1",
        pageSize: "10",
        essayChannel: "政策驿站",
        essayType: "政策解读",
      },
      list: [],
    };
  },
  mounted() {
    this.getFindAdByLocation();
  },
  methods: {
    // 广告跳转
    advertiseClick() {
      if (this.advertiseImg.active) {
        window.location.href = this.advertiseImg.active;
      }
    },
    // 跳转文章详情
    detailsClick(data) {
      if (data.essayUrl) {
        // window.open(data.essayUrl); // 新窗口
        window.location.href = data.essayUrl;
      } else {
        this.$router.push({
          path: "/policyLibrary/articleDetails",
          query: {
            tessayId: data.id,
          },
        });
      }
    },
    goQuiz(index, title) {
      this.listQuery.essayType = title;
      //当切换主题时，清空已查询出来的数据
        this.listQuery.page = 1;
        this.finished = false;
        this.list=[];
      switch (title) {
        case "政策解读":
          this.getFindAllTEssay();
          break;
        case "政策汇总":
          this.getFindAllTEssay();
          break;
      }
    },
    onSearch() {
      this.isSearch = false;
    },
    searchFocus() {
      this.isSearch = false;
    },
    // 列表
    onLoad() {
      setTimeout(() => {
        this.getFindAllTEssay();
        this.loading = true;
      }, 500);
    },
    // 获取文章列表
    getFindAllTEssay() {
      findAllTEssay(this.listQuery).then((res) => {
        this.loading = false;
        let rows = res.data.data.list;;
        this.list = this.list.concat(rows);
        if (rows == null || rows.length === 0) {
          this.finished = true;
        }else {
            if (this.list.length >= res.data.data.total) {
                this.finished = true; // 加载结束
            }else{
                this.listQuery.page++;
            }
        }
      });
    },
    // 广告详情
    getFindAdByLocation() {
      findAdByLocation({ location: 2 }).then((res) => {
        if (res.data.status == 200) {
          this.advertiseImg.img = res.data.data.advertImage;
          this.advertiseImg.active = res.data.data.advertUrl;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.PolicyLibrary {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  .title_img {
    .van-image {
      height: 190px;
    }
  }
}

.search_box {
  width: 100%;
  padding: 0 15px;
  position: relative;

  display: flex;
  .search {
    width: 90%;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
    position: absolute;
    top: -46px;
    left: 5%;
    z-index: 10;
    .van-search {
      padding: 15px 12px;
    }
    .search_list {
      width: 100%;
      padding: 10px;
      background: #f9f9f9;
      .search_list_box {
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid;
        padding: 17px 0;
        ::v-deep .van-icon-search {
          font-size: 19px;
          padding-right: 8px;
        }
      }
    }
  }
}

.PolicyLibrary_bottom {
  width: 100%;
  background: #fff;
  padding-top: 35px;
  ::v-deep .van-tab {
    font-size: 16px;
  }
  ::v-deep .van-tab--active {
    font-size: 20px;
  }
  .article_list {
    width: 100%;
    padding: 8px 20px;
    .article_list_box {
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid #e8e8e8;
      .article_list_top {
        width: 100%;
        display: flex;

        .article_list_top_lf {
          flex: 1;
          position: relative;
          margin-right: 10px;
          h4 {
            font-size: 16px;
            // font-weight: bold;
          }
          .num_time {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-top: 10px;
            color: #bababa;
            font-size: 12px;
            span {
              padding-left: 5px;
            }
          }
          .label {
            position: absolute;
            top: 23px;
            right: 0;
          }
        }

        .article_list_top_img {
          width: 94px;
          min-width: 94px;
          border-radius: 8px;
          overflow: hidden;
        }
      }
      .article_list_bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        color: #bababa;
        font-size: 12px;
        span {
          padding-left: 6px;
        }
      }
    }
  }
  .questions_but {
    width: 100%;
    padding: 10px;
    position: absolute;
    bottom: 10px;
    left: 0;
    .van-button {
      width: 100%;
    }
  }
}
</style>
